import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";

function App() {
  const [count, setCount] = useState(1);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    loading,
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
    isClaimed,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const connectWallet = () => {
    connectBrowserWallet();
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData, contractDetails]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);
  return (
    <>
      <section className="runner-container">
        <div className="runner-links">
          <a
            href="https://opensea.io/collection/moon-goblz"
            target="_blank"
            rel="noreferrer"
            className="runner-link-item"
          >
            <h2>Opensea</h2>
          </a>
          <div>
            <a
              href="https://etherscan.io/address/0x72bbc77668f8a5fba15333126548a82d27186dfd"
              target="_blank"
              rel="noreferrer"
              className="runner-link-item"
            >
              <h2>Etherscan</h2>
            </a>
            <a
              href="https://twitter.com/moongoblz"
              target="_blank"
              rel="noreferrer"
              className="runner-link-item greed-twitter"
            >
              <h2>Twitter</h2>
            </a>
          </div>
        </div>

        <div className="runner-form">
          <div className="runner-wrapper">
            <div className="greed-minter">
              <h2>moongoblz</h2>
              {!!userData ? (
                <>
                  {!!contractDetails && (
                    <p className="counter">{mintedCount} / 6666</p>
                  )}
                  <div className="runner-form-counter">
                    <button
                      onClick={handleCounter}
                      type="button"
                      className="runner-btn"
                      data-pos="minus"
                      disabled={loading || isClaimed}
                    >
                      -
                    </button>
                    <span> {count} </span>
                    <button
                      onClick={handleCounter}
                      type="button"
                      className="runner-btn"
                      data-pos="plus"
                      disabled={loading || isClaimed}
                    >
                      +
                    </button>
                    <button
                      onClick={handleCustomMint}
                      type="button"
                      className="claim-btn"
                      disabled={loading || isClaimed}
                    >
                      Mint
                    </button>
                  </div>
                  <div className="runner-claimers">
                    <button
                      onClick={() => mint(3)}
                      type="button"
                      className="claim-btn"
                      disabled={loading || isClaimed}
                    >
                      Mint 3
                    </button>
                    <button
                      onClick={() => mint(5)}
                      type="button"
                      className="claim-btn"
                      disabled={loading || isClaimed}
                    >
                      Mint 5
                    </button>
                    <button
                      onClick={() => mint(20)}
                      type="button"
                      className="claim-btn"
                      disabled={loading || isClaimed}
                    >
                      Mint 20
                    </button>
                  </div>
                  {transactionHash && (
                    <a
                      href={`https://etherscan.io/tx/${transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                      className="claim-btn"
                    >
                      See transaction
                    </a>
                  )}
                </>
              ) : (
                <button
                  type="button"
                  className="connect-wallet"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
